export const NOTES = Object.freeze([
  {
    property: 'one',
    title: '1 грн.',
  },
  {
    property: 'two',
    title: '2 грн.',
  },
  {
    property: 'five',
    title: '5 грн.',
  },
  {
    property: 'ten',
    title: '10 грн.',
  },
  {
    property: 'twenty',
    title: '20 грн.',
  },
  {
    property: 'fifty',
    title: '50 грн.',
  },
  {
    property: 'one_hundred',
    title: '100 грн.',
  },
  {
    property: 'two_hundred',
    title: '200 грн.',
  },
  {
    property: 'five_hundred',
    title: '500 грн.',
  },
])
