<template>
  <div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Дата інкасації:</span>
      <span class="font-weight-medium">
        {{ encashment.created_at | getShortDate }}
        <sup>{{ encashment.created_at | getShortTime }}</sup>
      </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Тип інкасації:</span>
      <span class="font-weight-medium"> {{ encashment.manual_recalculation ? 'Вручну' : 'Авто' }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Партнер:</span>
      <router-link
        :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: encashment.balance_holder.id } }"
        class="text-decoration-none font-weight-medium"
      >
        {{ encashment.balance_holder.name }}
      </router-link>
    </div>
    <div>
      <span class="mr-2">Пункт:</span>
      <router-link
        :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: encashment.service_point.id } }"
        class="text-decoration-none font-weight-medium"
      >
        {{ encashment.service_point.name }}
        <span class="caption">
          {{ encashment.service_point.address.city }}, {{ encashment.service_point.address.street }},
          {{ encashment.service_point.address.building_number }}
        </span>
      </router-link>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Термінал:</span>
      <router-link
        :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: encashment.terminal.id } }"
        class="text-decoration-none font-weight-medium"
      >
        T {{ encashment.terminal.id }}
      </router-link>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Неспалювальний лічильник:</span>
      <span class="font-weight-medium"> {{ encashment.total_counter }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Днів після попередньої інкасації:</span>
      <span class="font-weight-medium"> {{ encashment.days_after_prev_encashment }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Інкасатор:</span>
      <span v-if="encashment.collector" class="font-weight-medium"
        >{{ encashment.collector.first_name }} {{ encashment.collector.last_name }}
      </span>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex align-baseline">
      <span class="mr-2">Сума інкасації, грн.:</span>
      <span class="font-weight-medium"> {{ encashment.cash_sum }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Ручний перерахунок, грн.:</span>
      <span class="font-weight-medium"> {{ encashment.actual_sum }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Технічній вброс, грн.:</span>
      <span class="font-weight-medium"> {{ encashment.technical_payment }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">
        Відрахування, грн.:
        <sup>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
            </template>
            <span>Відрахування на з/п, матеріали, прибирання, грн.</span>
          </v-tooltip>
        </sup>
      </span>
      <span class="font-weight-medium"> {{ encashment.payout }} </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Різниця, грн.:</span>
      <span :class="[{ 'alert-difference': encashment.difference > 50 || encashment.difference < -50 }, 'font-weight-medium']">
        {{ difference }}
      </span>
    </div>
    <div class="d-flex align-baseline">
      <span class="mr-2">Заповнив:</span>
      <span v-if="encashment.creator" class="font-weight-medium">
        {{ encashment.creator.first_name }} {{ encashment.creator.last_name }}
      </span>
    </div>
  </div>
</template>

<script>
import ConvertDate from '@/mixins/convertDate'

export default {
  name: 'EncashmentInfo',

  mixins: [ConvertDate],

  props: {
    encashment: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentCompanyId: {
      required: true,
      default: null,
    },
  },

  computed: {
    difference() {
      return this.encashment.actual_sum + this.encashment.payout + this.encashment.technical_payment - this.encashment.cash_sum
    },
  },
}
</script>

<style scoped lang="scss">
.alert-difference {
  color: #f44336;
}
</style>
